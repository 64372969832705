import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import {Button, Col, notification, Row, Typography} from "antd";
import {firestore} from "./firebase";
import SignatureCanvas from "react-signature-canvas";


function App() {
	const [state, setState] = useState({
		pdfData: null,
		generatedPdf: "",
		signature: "",
		loading: false,
	});

	const buyerRef = useRef();
	const sellerRef = useRef();
	const urlParams = new URLSearchParams(window.location.search);
	const id = urlParams.get("id");

	useEffect(() => {
		onComponentMountAsync(id);
	}, [id]);
	const onComponentMountAsync = async (id) => {

		const billsDoc = await firestore()
			.doc("bills/" + id)
			.get();
		setState({...state, pdfData: billsDoc.data()});
	};

	const uploadPdf = async () => {
		setState({...state, loading: true});
		if (buyerRef.current.isEmpty() && sellerRef.current.isEmpty()) {
			setState({...state, loading: false});
			return console.log("empty");
		}
		await firestore().doc("bills/" + id).update({
			buyerSignature: buyerRef.current.getTrimmedCanvas().toDataURL("image/png"),
			sellerSignature: sellerRef.current.getTrimmedCanvas().toDataURL("image/png")
		})

		setState({...state, loading: false});
		notification.success({ message: "Success!", description: "Bill of Sale signed successfully!" })
		return window.location.replace("https://transmigrantesaguila.com");
	};

	return (
		<div style={{backgroundColor: "grey", height: 1200, width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
			<Row style={{ alignItems: "center" }}>
				<Col xs={24} sm={20} md={16} lg={24} xl={30}>
					<Row
						style={{
							backgroundColor: "#fff",
							margin: "20px 0",
							padding: "20px 0",
							height: 700,

						}}
					>
						{/*<Col align={"middle"} span={24}>*/}
						{/*    <img style={{ width: 160, height: 160 }} alt={"logo"} src={logo}/>*/}
						{/*</Col>*/}
						<Col align={"middle"} xs={24} sm={18} md={20} lg={24} xl={30} style={{height: 100}}>
							<Typography.Title level={2}>BILL OF SALE</Typography.Title>
						</Col>
						<Col align={"middle"} xs={12} sm={8} md={12} lg={12} xl={12}>
							<Typography.Title level={4}>Buyer Info</Typography.Title>
							<p>Name: {state.pdfData?.buyerName}</p>
							<p>Address: {state.pdfData?.buyerAddress}</p>
							<p>Phone #: {state.pdfData?.buyerPhone}</p>
						</Col>
						<Col align={"middle"} xs={12} sm={8} md={12} lg={12} xl={12}>
							<Typography.Title level={4}>Seller Info</Typography.Title>
							<p>Name: {state.pdfData?.sellerName}</p>
							<p>Address: {state.pdfData?.sellerAddress}</p>
							<p>Phone #: {state.pdfData?.sellerPhone}</p>
						</Col>
						<Col align={"middle"} xs={24} sm={12} md={12} lg={24} xl={24}>
							<Typography.Title level={4}>Vehicle Information</Typography.Title>
							<Row>
								<Col xs={12} sm={12} md={12} lg={12} xl={10}>
									<p>Make: {state.pdfData?.vehicleMake}</p>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={10}>
									<p>Year: {state.pdfData?.vehicleYear}</p>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={10}>
									<p>VIN #: {state.pdfData?.vehicleVin}</p>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={10}>
									<p>Price: ${state.pdfData?.vehiclePrice}</p>
								</Col>
							</Row>
						</Col>
						<Col
							xs={24} sm={24} md={24} lg={24} xl={24}
							align={"middle"}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<p>Buyer Signature:</p>
							<SignatureCanvas
								backgroundColor={"#f2f2f2"}
								penColor={"#000"}
								ref={buyerRef}
								canvasProps={{
									width: "300vw",
									height: 70,
									className: "sigCanvas",
								}}
							/>
						</Col>
						<Col
							xs={24} sm={24} md={24} lg={24} xl={24}
							align={"middle"}
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<p>Seller Signature:</p>
							<SignatureCanvas
								backgroundColor={"#f2f2f2"}
								penColor={"#000"}
								ref={sellerRef}
								canvasProps={{
									width: "300vw",
									height: 70,
									className: "sigCanvas",
								}}
							/>
						</Col>
					</Row>
				</Col>
				<Col align={"middle"} xs={24} sm={20} md={12} lg={20} xl={30}>
					<Button
						loading={state.loading}
						style={{margin: "0 auto"}}
						onClick={() => uploadPdf()}
					>
						Finish
					</Button>
				</Col>
			</Row>
		</div>
	);
}

export default App;
