import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyC4e45fNYnF4FV24zP-izhp6-_ckBXMItc",
	authDomain: "sma-aguila.firebaseapp.com",
	databaseURL: "https://sma-aguila.firebaseio.com",
	projectId: "sma-aguila",
	storageBucket: "sma-aguila.appspot.com",
	messagingSenderId: "893347635076",
	appId: "1:893347635076:web:af0678832d6ce1b22ec7d1",
	measurementId: "G-84MP1ZRJEK"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore;
export const storage = firebase.storage;
